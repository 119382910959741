import { useStyles } from './waitingRoom.styles'
import Image from 'components/image'

type WaitingRoomPropType = {
    pageNumber: number
    preEntryText: string
    queueText: string
}

export const WaitingRoom = ({ pageNumber, preEntryText, queueText }: WaitingRoomPropType) => {
    const { classes } = useStyles({ pageNumber })

    return (
        <div
            className={classes.pageWrapper}
            role="main"
        >
            <section
                aria-describedby="event-description"
                aria-labelledby="event-heading"
                className={classes.contentContainer}
            >
                <div className={classes.copyWrapper}>
                    <header
                        className={classes.logoWrapper}
                        role="banner"
                    >
                        <svg
                            aria-labelledby="logo-title"
                            fill="none"
                            height="36"
                            role="img"
                            viewBox="0 0 208 80"
                            width="96"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title id="logo-title">Vibee Logo</title>
                            <g clipPath="url(#clip0_1314_28161)">
                                <path
                                    d="M17.8445 79.4637L0 40.5564H9.34068L20.0761 63.9291L30.7746 40.5564H40.1153L22.3077 79.4637H17.8486H17.8445Z"
                                    fill="black"
                                />
                                <path
                                    d="M49.6283 28.8528C50.5349 29.7109 50.9903 30.7529 50.9903 31.9788C50.9903 33.2047 50.5349 34.2468 49.6283 35.1049C48.7217 35.963 47.5978 36.3959 46.2645 36.3959C44.9313 36.3959 43.836 35.9669 42.9172 35.1049C41.9983 34.2468 41.5388 33.2047 41.5388 31.9788C41.5388 30.7529 41.9983 29.7147 42.9172 28.8528C43.836 27.9946 44.9518 27.5656 46.2645 27.5656C47.5772 27.5656 48.7217 27.9946 49.6283 28.8528ZM41.9531 79.4675H50.5349V40.5602H41.9531V79.4675Z"
                                    fill="black"
                                />
                                <path
                                    d="M75.5417 40.3112C81.2889 40.3112 86.2156 42.2535 90.3219 46.1381C94.4076 49.9959 96.4464 54.6697 96.4464 60.1556C96.4464 65.6416 94.4035 70.277 90.3219 74.1386C86.2156 78.0462 81.2848 80 75.5417 80C71.0539 80 66.9969 78.7396 63.3664 76.2227V79.5058H54.5959V27.9219H63.3664V44.0196C66.9969 41.5486 71.0539 40.315 75.5417 40.315V40.3112ZM75.5417 71.8056C78.8686 71.8056 81.7155 70.664 84.0865 68.3807C86.4822 66.0974 87.6801 63.3545 87.6801 60.1556C87.6801 56.9568 86.4822 54.1755 84.0865 51.8922C81.7155 49.609 78.8686 48.4673 75.5417 48.4673C72.2148 48.4673 69.29 49.609 66.923 51.8922C64.5519 54.1755 63.3705 56.9299 63.3705 60.1556C63.3705 63.3813 64.556 66.1013 66.923 68.3807C69.29 70.664 72.1656 71.8056 75.5417 71.8056Z"
                                    fill="black"
                                />
                                <path
                                    d="M136.738 71.0624C134.999 73.5602 132.73 75.5714 129.933 77.1C126.68 78.935 123.164 79.8544 119.382 79.8544C113.61 79.8544 108.683 77.9122 104.602 74.0276C100.516 70.1698 98.4771 65.496 98.4771 60.01C98.4771 54.5241 100.52 49.8542 104.602 45.9925C108.683 42.1079 113.61 40.1656 119.382 40.1656C125.154 40.1656 130.056 42.1079 134.166 45.9925C138.248 49.8542 140.291 54.5241 140.291 60.01V63.366H108.002C108.708 65.9327 110.168 67.99 112.388 69.5453C114.48 70.959 116.81 71.6638 119.382 71.6638C123.591 71.6638 126.844 70.1008 129.137 66.9671L136.734 71.0624H136.738ZM130.765 55.9147C129.883 53.6775 128.419 51.8654 126.38 50.4786C124.288 49.065 121.958 48.3601 119.386 48.3601C116.814 48.3601 114.484 49.065 112.392 50.4786C110.349 51.8654 108.888 53.6813 108.006 55.9147H130.769H130.765Z"
                                    fill="black"
                                />
                                <path
                                    d="M180.586 71.0624C178.847 73.5602 176.578 75.5714 173.781 77.1C170.528 78.935 167.012 79.8544 163.23 79.8544C157.458 79.8544 152.531 77.9122 148.45 74.0276C144.364 70.1698 142.325 65.496 142.325 60.01C142.325 54.5241 144.368 49.8542 148.45 45.9925C152.531 42.1079 157.458 40.1656 163.23 40.1656C169.002 40.1656 173.904 42.1079 178.014 45.9925C182.096 49.8542 184.139 54.5241 184.139 60.01V63.366H151.85C152.556 65.9327 154.016 67.99 156.236 69.5453C158.328 70.959 160.658 71.6638 163.23 71.6638C167.439 71.6638 170.692 70.1008 172.985 66.9671L180.582 71.0624H180.586ZM174.613 55.9147C173.732 53.6775 172.267 51.8654 170.228 50.4786C168.136 49.065 165.806 48.3601 163.234 48.3601C160.662 48.3601 158.332 49.065 156.24 50.4786C154.197 51.8654 152.737 53.6813 151.855 55.9147H174.618H174.613Z"
                                    fill="black"
                                />
                                <path
                                    d="M202.476 4.64875C199.284 1.80234 195.145 -0.0288662 191.014 0.0209363C190.862 0.0132744 190.711 0.00944013 190.563 0.00560917C189.788 -0.0135457 189.025 0.0171105 188.278 0.0860679C188.934 0.994007 189.595 1.90578 190.247 2.81755C191.088 3.98982 191.9 5.18125 192.741 6.35352C193.988 8.08895 195.285 9.79373 196.491 11.5521C197.327 12.7704 198.127 14.0231 198.804 15.3256C199.649 16.9538 199.301 18.6394 198.681 20.2523C197.738 22.7003 196.224 24.8226 194.124 26.5504C192.007 28.2935 189.71 29.8297 186.842 30.1323C184.684 30.3584 182.654 29.9599 181.202 28.0942C179.647 26.0907 179.852 24.0104 180.574 21.8728C181.501 19.1336 183.339 16.9461 185.636 15.1034C187.437 13.6592 189.447 12.5176 191.913 12.0578C191.736 11.6632 191.642 11.307 191.437 11.012C190.419 9.54089 189.377 8.08512 188.344 6.62169C187.515 5.44559 186.686 4.26948 185.854 3.0972C185.337 2.36932 184.816 1.64144 184.295 0.913558C175.34 3.79827 169.904 13.1305 173.133 22.2061C176.357 31.2816 183.519 40.2001 189.037 47.8161C189.603 48.5478 190.551 48.5478 191.117 47.8199C196.642 40.2193 203.977 31.0786 207.128 21.9417C209.236 15.8237 207.46 9.09266 202.48 4.65257L202.476 4.64875Z"
                                    fill="black"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1314_28161">
                                    <rect
                                        width="208"
                                        height="80"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </header>
                    <h1
                        id="event-heading"
                        className={classes.artistName}
                    >
                        The Weeknd
                    </h1>
                    <h3 className={classes.boldHeader}>Rose Bowl Stadium</h3>

                    <p className={classes.preEntryText}>{preEntryText}</p>
                    <p className={classes.queueText}>{queueText}</p>
                </div>
                <div className={classes.imageWrapper}>
                    <Image
                        alt="the-weeknd-rose-bowl-stadium"
                        className={classes.eventImage}
                        height={531}
                        width={426}
                        src="https://s3.us-west-2.amazonaws.com/vibee.com/de73c027-6104-4b87-ab54-7672d4f71be4/1a8e0a80-2ef1-4b5d-8743-4db3fbc4dd86"
                    />
                </div>
            </section>
            <div className={classes.waitingRoomfooter}>
                <p>This page will automatically refresh, please do not close your browser.</p>
            </div>
        </div>
    )
}
