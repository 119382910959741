import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { isMobile, isTablet } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { EventJSON } from '../../../../events-service/src/models/event'
import { HotelJSON } from '../../../../events-service/src/models/hotel'
import { HotelRoomJSON } from '../../../../events-service/src/models/hotelRoom'
import { HotelRoomTierJSON } from '../../../../events-service/src/models/hotelRoomTier'
import { TicketJSON } from '../../../../events-service/src/models/ticket'
import { TierJSON } from '../../../../events-service/src/models/tier'
import { ZoneJSON } from '../../../../events-service/src/models/zone'
import { eventsWithoutJwtClient } from '../../apollo-client'
import BodyClass from '../../components/bodyClass'
import EventBottomHero from '../../components/eventBottomHero'
import { EventFAQ } from 'components/eventFAQ'
import EventFeaturesAndBenefitsSection from '../../components/eventFeaturesAndBenefitsSection'
import EventMap from '../../components/eventMap'
import { EventTimeLine } from '../../components/Slug/EventTimeLine'
import { HotelCard } from 'components/Hotel/hotelCard'
import SelectTicketNotification from '../../components/selectTicketNotification'
import { TicketTypeSelection } from '../../components/Slug/TicketTypeSelection'
import { AddToCartInfo, ShoppingCartState } from '../../store'
import { checkCode, fetchCart } from '../../store/actions/fetchCart'
import { getInventory } from '../../store/actions/fetchOrders'
import { dateRange, getShoulderNightsByHotelId, getTimeZone, now } from '../../util/dates'
import useHive from '../../util/hiveHooks'
import Scroll from 'react-scroll'
import { MobileNextArrow, MobilePrevArrow, NextArrow, PrevArrow } from '../../components/slickArrow'
import {
    calculateInventoryIncludeCard,
    checkMinOccupancy,
    getEventNameOverride,
    getHotelNumInfos,
    getTicketNumInfo
} from '../../util/event'
import { getImage } from '../../util/getEventHeaderImageByDevice'
import GuestsModal from '../../components/guestsModal'
import getConfig from 'next/config'
import PreSaleModal from '../../components/preSaleModal'
import { HotelRoomTierPriceJSON } from '../../../../events-service/src/models/hotelRoomTierPrice'
import BreadCrumbsNavbar from '../../components/breadCrumbsNavbar'
import { useAppSelector } from '../../hooks'
import { GET_EVENTS_PAGE_QUERY } from '../../graphQL/events/getEventsPageQuery.graphql'
import Image from 'components/image'
import { getRoomTierTimeSaleAble } from 'util/pricing'
import HotelCardImages from 'components/hotelCardImages'
import { TicketExperienceJSON } from '../../../../events-service/src/models/ticketExperience'
import { TicketExperienceTierJSON } from '../../../../events-service/src/models/ticketExperienceTier'
import { useCalculate } from 'hooks/useCalculate'
import { useWindowWidth } from 'hooks/useWidth'
import {
    APP_NAME,
    GAProductType,
    googleAnalyticsEvent,
    GoogleAnalyticsEventActionType
} from 'components/Google/GoogleAnalytics'
import { EventNotIncludeTicketBanner } from 'components/EventNotIncludeTicketBanner'
import { DateRangePickerModalComponent } from 'components/DatePickerModal'
import { useHotelOnly } from 'hooks/useHotelOnly'
import { EventNotIncludeTicketModal } from 'components/eventNotIncludeTicketModal'
import Skeleton from '@mui/material/Skeleton'
import { FULL_WIDTH, SIZE_22, SIZE_30 } from 'styles/theme'
import { EventTop } from 'components/Slug/EventTop'
import { EventIntroduction } from 'components/Slug/EventIntroduction'
import {
    FaqsCategoriesType,
    FaqsType,
    HotelNumInfoType,
    NavbarUrlType,
    OptionType,
    TicketNumInfoType,
    TicketTierItemType
} from 'types/event'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useStyles } from 'components/Slug/styles/slug.styles'
import { defaultHotelNumObject, defaultTicketNumObject, INIT_UUID, ON_SALE } from 'consts'
import { defaultTicketState, useTicketStatus } from 'hooks/useTicketStatus'
import { defaultHotelState, useHotelStatus } from 'hooks/useHotelStatus'
import { WaitingRoom } from 'components/WaitingRoom'

const { publicRuntimeConfig } = getConfig()

let ScrollLink = Scroll.Link

function getSettings(arrowParent: string) {
    return {
        infinite: false,
        slidesToShow: 5,
        cssEase: 'ease',
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        speed: '600',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                    prevArrow: <MobilePrevArrow parent={arrowParent} />,
                    nextArrow: <MobileNextArrow parent={arrowParent} />
                }
            },
            {
                breakpoint: 767,
                settings: {
                    infinite: false,
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    prevArrow: <MobilePrevArrow parent={arrowParent} />,
                    nextArrow: <MobileNextArrow parent={arrowParent} />
                }
            }
        ]
    }
}

export async function getServerSideProps(context: {
    res: { setHeader: (k: string, v: string) => void; req: any }
    params: { slug: string }
    query: any
}) {
    try {
        context.res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')
        const { data } = await eventsWithoutJwtClient.query({
            query: GET_EVENTS_PAGE_QUERY,
            variables: {
                slug: context?.params?.slug,
                password: context?.query?.password || ''
            },
            fetchPolicy: 'no-cache'
        })

        const subDomain = publicRuntimeConfig.NEXT_PUBLIC_SUBDOMAIN
        let currentDomainForCors = 'https://vibee.com'
        if (subDomain) {
            currentDomainForCors = `https://${subDomain}.vibee.com`
        }

        return {
            props: {
                event: data.getEvent,
                password: context?.query?.password || '',
                meta: {
                    ...data.getEvent.meta,
                    url: currentDomainForCors + '/events/' + context?.params?.slug
                }
            }
        }
    } catch (e) {
        console.error('Error in server side props in events', e)
        return {
            redirect: {
                destination: '/',
                permanent: true
            }
        }
    }
}

export const checkTourData = (
    event: EventJSON,
    groupSize: number,
    inventory: Record<string, number>,
    ticket: TicketJSON
) => {
    const ticketAndExperiencePrice = getTicketAndExperiencePrice(
        event,
        groupSize,
        inventory,
        ticket
    )
    const eventHotelArr: boolean[] = []
    event?.hotels.forEach((hotel, index) => {
        hotel.rooms.forEach((r, i) => {
            const totalQuantity =
                ticketAndExperiencePrice.totalQuantity > 0
                    ? ticketAndExperiencePrice.totalQuantity
                    : 0
            const flag = checkMinOccupancy(r, inventory, totalQuantity)
            eventHotelArr.push(flag)
        })
    })
    if (ticketAndExperiencePrice.error !== '') {
        return false
    } else {
        return eventHotelArr.includes(true)
    }
}

const pxToNumber = (px: string) => {
    const num = Number(px.replace('px', ''))
    return num
}

export const getRoomTier = (
    groupSize: number,
    inventory: Record<string, number>,
    room: HotelRoomJSON,
    tierIndex?: number
): HotelRoomTierJSON | undefined => {
    const tiers = room.tiers.filter(
        (tier) =>
            inventory[tier.id] > 0 &&
            tier.onSale &&
            tier.prices.filter((price) => inventory[price.id] > 0 && price.size <= groupSize)
                .length > 0
    )
    if (tiers.length > 1 && tierIndex) {
        return tiers[tierIndex]
    } else {
        return tiers[0]
    }
}

export const getTicketTier = (
    ticket: TicketJSON,
    inventory: Record<string, number>
): TierJSON | undefined => {
    const ticketTiers = ticket.tiers
        .filter((t) => inventory[t.id] > 0)
        .sort((a, b) => a.price - b.price)
    if (ticketTiers.length) return ticketTiers[0]
}

export const getLowerTicketExperienceTier = (
    ticketTierId: string,
    ticketExperience: TicketExperienceJSON
): TicketExperienceTierJSON | undefined => {
    const ticketExperienceTiers = ticketExperience.tiers
        .filter((t) => t.ticketTierID === ticketTierId)
        .sort((a, b) => a.price - b.price)
    if (ticketExperienceTiers.length) return ticketExperienceTiers[0]
}

export const getLowerTicketTier = (ticket: TicketJSON): TierJSON | undefined => {
    const ticketTiers = ticket.tiers.filter((t) => t).sort((a, b) => a.price - b.price)
    if (ticketTiers.length) return ticketTiers[0]
}

export const getTicketExperiencePrice = (
    experience: TicketExperienceJSON | undefined,
    tiersTotal: TicketTierItemType[]
) => {
    let ticketExperiencePrice = 0
    if (experience) {
        tiersTotal.forEach((ticketTier) => {
            const experienceTier = experience.tiers.find(
                (tier) => tier.ticketTierID === ticketTier.id
            )
            if (!experienceTier) return
            ticketExperiencePrice +=
                (experienceTier.price + experienceTier.taxesAndFees) * ticketTier.quantity
        })
    }
    return ticketExperiencePrice
}

export const getTicketAndExperiencePrice = (
    event: EventJSON,
    groupSize: number,
    inventory: Record<string, number>,
    ticket: TicketJSON
) => {
    let temporaryInventory = { ...inventory }
    let remainingNeedQuantity = groupSize
    const ticketTiersTotal: TicketTierItemType[] = []
    let ticketTierPrice = 0
    let totalQuantity = 0
    while (remainingNeedQuantity > 0) {
        const tier = getTicketTier(ticket, temporaryInventory)
        if (!tier) break
        const quantity =
            temporaryInventory[tier.id] < remainingNeedQuantity
                ? temporaryInventory[tier.id]
                : remainingNeedQuantity
        ticketTiersTotal.push({
            id: tier.id,
            quantity: quantity,
            price: tier.price + tier.taxesAndFees
        })
        totalQuantity += quantity
        ticketTierPrice += (tier.price + tier.taxesAndFees) * quantity
        temporaryInventory[tier.id] -= quantity
        remainingNeedQuantity -= quantity
    }
    if (remainingNeedQuantity > 0) {
        return { ticketAndExperiencePrice: 0, error: 'Sold Out', totalQuantity }
    }
    const experience = event && event.ticketExperiences?.find((t) => t.ticketID === ticket.id)
    const ticketExperiencePrices = getTicketExperiencePrice(experience, ticketTiersTotal)
    return {
        ticketAndExperiencePrice: ticketTierPrice + ticketExperiencePrices,
        error: '',
        totalQuantity
    }
}

const GUEST_OPTIONS = [
    { value: 1, label: '1 Guest', isDisabled: false },
    { value: 2, label: '2 Guests', isDisabled: false },
    { value: 3, label: '3 Guests', isDisabled: false },
    { value: 4, label: '4 Guests', isDisabled: false },
    { value: 5, label: '5 Guests', isDisabled: false },
    { value: 6, label: '6 Guests', isDisabled: false },
    { value: 7, label: '7 Guests', isDisabled: false },
    { value: 8, label: '8 Guests', isDisabled: false }
]

interface HotelPrice {
    price: number
    error: string
}

const DEFAULT_GUEST_OPTION = GUEST_OPTIONS[1]

const Event: NextPage<any> = ({ event, password }: { event: EventJSON; password: string }) => {
    const router = useRouter()
    const dispatch = useDispatch()
    const eventSlug = router.query.slug as string
    const isF1 = eventSlug.indexOf('-f1') !== -1 || eventSlug.indexOf('-F1') !== -1
    const pathName = router.query.slug as string
    const isTheWeekndRoute = pathName.includes('the-weeknd-2025')
    const [email, setEmail] = useState<string>('')
    const [hiveState, hiveSubscribe] = useHive(
        () => {
            setEmail('')
            console.log('Success: Added to segment: ' + event.name)
        },
        (data: any) => {
            setEmail('')
            console.log('Error: Add to segment ' + event.name + ' failed:', data)
        }
    )

    const [inCartRooms, setInCartRooms] = useState<Record<string, number>>({})
    const availableZones = event?.zones?.filter((zone) => {
        return event.tickets.find((ticket) => ticket.zones[0] && ticket.zones[0].id === zone.id)
    })
    const hasZones = availableZones.length > 0
    const initSelectedZone = hasZones ? availableZones[0] : undefined
    const [selectedZone, setSelectedZone] = useState<ZoneJSON | undefined>(initSelectedZone)
    const [selectedTicketZone, setSelectedTicketZone] = useState<ZoneJSON | undefined>(
        initSelectedZone
    )
    const [selectedHotelId, setSelectedHotelId] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [inventory, setInventory] = useState<Record<string, number>>({})
    const cart: ShoppingCartState = useAppSelector((state) => state.shoppingCartReducer)
    const eventTickets = event?.tickets?.sort((a, b) => {
        if (a.position !== b.position) {
            return a.position - b.position
        }
        return a.tiers[0]?.price - b.tiers[0]?.price
    })
    const queryParams = password ? `?password=${password}` : ''
    const isOnlySellHotel = event.hotels.length > 0 && event.tickets.length === 0
    const isOnlySellTickets =
        event.tickets.length > 0 && !event.hotels.some((hotel) => hotel.rooms.length > 0)
    const hasAddonsPage =
        event.addOnItems.length > 0 &&
        event.addOnItems.some(
            (addOnItem) => !addOnItem.required && !addOnItem.hidden && addOnItem.onSale
        )
    const scrollLinkNode = useRef<any>(null)
    const peopleNumber = 2
    const isShoulderNights = false
    const [submitTicketId, setSubmitTicketId] = useState<string>('')

    const initTicketSelect = () => {
        const g: Record<string, number> = {}
        event.tickets.forEach((t) => {
            g[t.id] = 0
        })
        return g
    }
    const [selectedTicketGroupSize, setSelectedTicketGroupSize] = useState(initTicketSelect())
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [showGuestsModal, setShowGuestsModal] = useState<boolean>(false)
    const [groupSizeOptions, setGroupSizeOptions] = useState<typeof GUEST_OPTIONS>(GUEST_OPTIONS)
    const [selectedGuestsOption, setSelectedGuestsOption] = useState<OptionType>(
        groupSizeOptions[1]
    )
    const [guestsSubmitting, setGuestsSubmitting] = useState<boolean>(false)
    const [selectedOptionIsPlaceholder, setSelectedOptionIsPlaceholder] = useState<boolean>(true)
    const [isPreSale, setIsPreSale] = useState<boolean>(false)
    const [inPreSaleInterval, setInPreSaleInterval] = useState<boolean>(false)
    const [showPreSalePopUp, setShowPreSalePopUp] = useState<boolean>(false)
    const [code, setCode] = useState('')
    const [codeError, setCodeError] = useState<string>('')
    const [isCodeValid, setIsCodeValid] = useState<boolean>(false)
    const [allImageCardGallery, setAllImageCardGallery] = useState<boolean>(false)
    const [showSingleImage, setShowSingleImage] = useState<boolean>(false)
    const [hotelImageUrl, setHotelImageUrl] = useState<any[]>([])
    const { isMobileWidth } = useWindowWidth()
    const [showEventNotIncludeTicketModal, setShowEventNotIncludeTicketModal] =
        useState<boolean>(false)
    const [isReserving, setIsReserving] = useState<boolean>(false)
    const [eventNameOverrideTicketNumber, setEventNameOverrideTicketNumber] = useState<string[]>([])
    const [showEventNotIncludeTicketBanner, setShowEventNotIncludeTicketBanner] =
        useState<boolean>(false)
    const [isDateRangePickerModalOpen, setIsDateRangePickerModalOpen] = useState<boolean>(false)
    const [hotelNumInfos, setHotelNumInfos] = useState<HotelNumInfoType>(defaultHotelNumObject)
    const [ticketNumInfos, setTicketNumInfos] = useState<TicketNumInfoType>(defaultTicketNumObject)
    const [faqs, setFaqs] = useState<FaqsType[]>([])
    const [faqsCategories, setFaqsCategories] = useState<FaqsCategoriesType[]>([])
    const [selectedGroupSize, setSelectedGroupSize] = useState<number>(2)
    const [hasAddToCart, setHasAddToCart] = useState<boolean>(false)
    const [hasBeenToPreview, setHasBeenToPreview] = useState<boolean>(false)
    const [navbarUrl, setNavbarUrl] = useState<NavbarUrlType>({
        accommodation: '',
        addOn: '',
        groupSize: '',
        preview: '',
        room: ''
    })
    const { getLocalStorage, setLocalStorage } = useLocalStorage()
    const localCartInfo = getLocalStorage('addToCartInfo')
    const { calculateCheapestPrice, getCheapestPrice, totalTiersData } = useCalculate({
        event: event,
        inventory: inventory
    })

    const shouldShowCountdown: boolean = localCartInfo?.shouldShowCountdown ?? false
    const { classes } = useStyles()
    const {
        ageRequirement,
        currency,
        dateDisplay,
        description,
        endDate,
        headerImage,
        headerVideo,
        itinerary,
        location,
        locationDisplay,
        realEventEndDate,
        requiresPresaleUntil,
        requiresPresaleUntilEnd,
        startDate,
        ticketTips,
        timezone
    } = event
    const ticketsStatus = useTicketStatus({
        calculateCheapestPrice,
        event,
        inPreSaleInterval,
        inventory,
        isCodeValid,
        isOnlySellHotel,
        isOnlySellTickets,
        isPreSale,
        totalTiersData
    })

    const hotelsStatus = useHotelStatus({
        calculateCheapestPrice,
        event,
        inPreSaleInterval,
        inventory,
        isCodeValid,
        isOnlySellHotel,
        isPreSale,
        shouldGetHotelsStatus: isOnlySellHotel,
        totalTiersData
    })
    const handleTicketNameResize = () => {
        let maxHeight = 0
        const ticketElementArray: HTMLElement[] = []
        event.tickets
            .filter((ticket) => !ticket.hidden)
            .map((t, index) => {
                const ticketNameElement: HTMLElement | null = document.getElementById(
                    `ticket_${index}`
                )
                if (ticketNameElement) {
                    ticketNameElement.style.height = 'auto'
                    ticketElementArray.push(ticketNameElement)
                    if (ticketNameElement.offsetHeight > maxHeight) {
                        maxHeight = ticketNameElement.offsetHeight
                    }
                }
            })
        if (maxHeight !== 0) {
            ticketElementArray.map((t) => (t.style.height = `${maxHeight}px`))
        }
    }

    const {
        addToCartDefaultValueRange,
        addToCartValue,
        cartFirstCheckDateOfLocationTimeRange,
        defaultValueRange,
        getCartFirstCheckDateRange,
        hasShoulderNights,
        maxSelectDate,
        minSelectDate,
        resetDatesToInitialEventDates,
        selectedDates,
        setMaxSelectDate,
        setMinSelectDate,
        updateDates,
        updateEventProp,
        textContent
    } = useHotelOnly({
        event,
        groupSize: selectedGroupSize,
        hasAddToCart,
        inventory,
        selectedHotelId
    })
    const hotel = event.hotels.find((hotel) => hotel.id === selectedHotelId)

    useEffect(() => {
        window.addEventListener('resize', handleTicketNameResize)
        return () => {
            window.removeEventListener('resize', handleTicketNameResize)
        }
    }, [])

    useEffect(() => {
        if (requiresPresaleUntil && requiresPresaleUntilEnd) {
            const activeStartDate = getTimeZone(requiresPresaleUntil, timezone)
            const activeEndDate = getTimeZone(requiresPresaleUntilEnd, timezone)
            if (activeStartDate < now(timezone) && now(timezone) < activeEndDate) {
                setInPreSaleInterval(true)
                if (!hasAddToCart) {
                    setIsPreSale(true)
                    setShowPreSalePopUp(true)
                }
                if (hasAddToCart && code !== cart.presaleCode) {
                    setCode(cart.presaleCode)
                    setIsCodeValid(true)
                }
            }
        }
    }, [])

    useEffect(() => {
        if (scrollLinkNode.current) {
            const ticket = eventTickets.find((item) => item.zones[0].id === selectedZone?.id)
            if (!ticket) return
            scrollLinkNode.current.scrollTo('ticket_' + ticket.id, {
                spy: true,
                smooth: true,
                duration: 800,
                delay: 0
            })
        }
    }, [eventTickets, selectedZone])

    useEffect(() => {
        if (getTimeZone(realEventEndDate, timezone).isBefore(now(timezone))) {
            console.log('Event End!')
            router.push('/')
        }
    }, [realEventEndDate, router, timezone])

    useEffect(() => {
        if (hasAddToCart) {
            const selectedTicketNumberInCart =
                cart.items.find((item) => item.type === 'ticket')?.itemID || ''
            const eventNameOverride = getEventNameOverride(event, selectedTicketNumberInCart)
            if (eventNameOverride !== '') {
                setShowEventNotIncludeTicketBanner(true)
            }
        }
    }, [cart, event, hasAddToCart])
    useEffect(() => {
        let ticketsInCart: Record<string, number> = {}
        const load = async () => {
            const shoppingCartID =
                window.localStorage.getItem('shoppingCartID') || cart.id || uuid()
            const cartData: ShoppingCartState = await fetchCart(
                publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                shoppingCartID
            )(dispatch)

            const notAdditionalOrder = cartData.purchaseAddOnsMemberId === INIT_UUID
            const hasAddToCart =
                cartData.items.length !== 0 && event.id === cartData.eventID && notAdditionalOrder
            const inventoryData = await getInventory(event.id, 8)
            const ticketsTierInCart = cartData.items.filter((i) => i.type === 'ticket.tier')
            const ticketsExperienceInCart = cartData.items.filter(
                (i) => i.type === 'ticket.experience.tier'
            )
            const roomsTierInCart = cartData.items.filter((i) => i.type === 'room.tier')
            event.tickets.forEach((t) => {
                const ticketTierInCart = ticketsTierInCart.find((tierInCart) => {
                    return t.tiers.find((tier) => {
                        return tier.id === tierInCart.itemID
                    })
                })

                ticketsInCart[t.id] = ticketTierInCart ? ticketTierInCart.quantity : 0
            })
            setInCartRooms(() => {
                roomsTierInCart.forEach((r) => {
                    inCartRooms[r.itemID] = r.quantity
                })
                return inCartRooms
            })
            if (hasZones && ticketsTierInCart.length > 0) {
                const inCartSelectedTicketZone = event.tickets.find(
                    (t) => t.id === ticketsTierInCart[0].parent
                )?.zones[0]
                setSelectedTicketZone(inCartSelectedTicketZone)
            }
            let option: OptionType | undefined
            if (event.id === localCartInfo.eventID) {
                option = GUEST_OPTIONS.find(
                    (guestOption) => guestOption.value === localCartInfo.groupSize
                )
                if (option) {
                    setSelectedOptionIsPlaceholder(false)
                }
            }
            setHasAddToCart(hasAddToCart)
            setSelectedGroupSize(option?.value || 2)
            setSelectedGuestsOption(option || DEFAULT_GUEST_OPTION)
            setInventory(calculateInventoryIncludeCard(inventoryData, cartData.items, event.hotels))
            setIsLoading(false)
        }
        load().catch(() => {
            setIsLoading(false)
        })
        setSelectedTicketGroupSize(ticketsInCart)
    }, [
        cart.id,
        dispatch,
        event,
        hasZones,
        inCartRooms,
        isOnlySellHotel,
        localCartInfo.eventID,
        localCartInfo.groupSize
    ])

    useEffect(() => {
        let hasBeenToPreview: boolean = false
        if (
            localCartInfo?.isShowShoppingBag !== undefined &&
            localCartInfo.comparedEventsIDInPreview !== undefined &&
            event.id === localCartInfo.comparedEventsIDInPreview
        ) {
            hasBeenToPreview = localCartInfo?.isShowShoppingBag
        }
        setHasBeenToPreview(hasBeenToPreview)
    }, [event, localCartInfo.comparedEventsIDInPreview, localCartInfo.isShowShoppingBag])

    useEffect(() => {
        window.localStorage.setItem('password', password)
    }, [password])

    useEffect(() => {
        const showFaqsCategories = event.faqCategories
            .filter((category) => {
                return event.faqs.some((faq) => {
                    return faq.categoryID === category.id
                })
            })
            .sort((a, b) => a.position - b.position)
        setFaqs(event.faqs.sort((a, b) => a.position - b.position))
        setFaqsCategories(showFaqsCategories)
    }, [event])

    useEffect(() => {
        if (isLoading) return
        const cardImg = document.querySelector('.js-card-img')
        const cardBody = document.querySelector('.js-card-body')

        if (cardImg && cardBody) {
            const cardBodyHeight = pxToNumber(getComputedStyle(cardBody, null).height)
            const cardImgHeight = pxToNumber(getComputedStyle(cardImg, null).height)
            if (cardBodyHeight < cardImgHeight) {
                const card = document.querySelector('.js-card')
                if (card) {
                    card.classList.add('align-items-center')
                }
            }
        }
    }, [isLoading])

    useEffect(() => {
        let navbarUrl = {
            accommodation: '#',
            addOn: '#',
            groupSize: `/events/${eventSlug}/group-size${queryParams}`,
            preview: '#',
            room: `#`
        }
        if (hasAddToCart) {
            let addOnUrl = `/events/${eventSlug}/add-ons${queryParams}`
            let roomUrl = `/events/${eventSlug}/${localCartInfo.previousHotelID}/${cart.groupSize}/${localCartInfo.previousSelectedTicketNumber}${queryParams}`
            if (!shouldShowCountdown) {
                addOnUrl = `#`
                roomUrl = `#`
            }
            navbarUrl = {
                ...navbarUrl,
                accommodation: `/events/${eventSlug}/choose-your-accommodation${queryParams}`,
                addOn: addOnUrl,
                room: roomUrl
            }
        }
        if (hasBeenToPreview) {
            navbarUrl = {
                ...navbarUrl,
                preview: `/events/${eventSlug}/checkout/preview${queryParams}`
            }
        }
        setNavbarUrl(navbarUrl)
    }, [
        eventSlug,
        hasAddToCart,
        localCartInfo.previousHotelID,
        localCartInfo.previousSelectedTicketNumber,
        shouldShowCountdown,
        queryParams
    ])

    useEffect(() => {
        let hotelOnlyData = {}
        switch (true) {
            case isOnlySellHotel:
                hotelOnlyData = {
                    checkInDate: event.checkInDate,
                    checkOutDate: event.checkOutDate,
                    firstCheckInDate: event.checkInDate,
                    firstCheckOutDate: event.checkOutDate
                }
                break
            default:
                hotelOnlyData = {
                    checkInDate: localCartInfo.firstCheckInDate,
                    checkOutDate: localCartInfo.firstCheckOutDate
                }
                break
        }
        if (localCartInfo?.hasClickNavbar && hasAddToCart) {
            const previousTicketItem =
                localCartInfo.previousTicketItems !== undefined
                    ? localCartInfo.previousTicketItems
                    : []
            const selectedTicketNumber =
                localCartInfo.previousSelectedTicketNumber !== undefined
                    ? localCartInfo.previousSelectedTicketNumber
                    : ''
            setLocalStorage('addToCartInfo', {
                ...localCartInfo,
                ...hotelOnlyData,
                eventID: cart?.eventID,
                groupSize: cart.groupSize,
                ticketItems: previousTicketItem,
                hotelID: localCartInfo.previousHotelID,
                selectedTicketNumber
            })
        }
    }, [localCartInfo?.hasClickNavbar, hasAddToCart])

    useEffect(() => {
        let anchorElement = document.getElementsByClassName('event-detail-wrapper')
        if (anchorElement && showGuestsModal) {
            anchorElement[0].classList.remove('modal-open')
        }
    }, [showGuestsModal])

    const maxGroupSizeQty = 8

    useEffect(() => {
        setTicketNumInfos(getTicketNumInfo(eventTickets, inventory, event))
    }, [eventTickets, inventory, event])

    useEffect(() => {
        setHotelNumInfos(getHotelNumInfos(event, inventory))
    }, [event, inventory])

    let ticketsInCart: Record<string, number> = {}
    cart?.items
        ?.filter((item) => item.type === 'ticket')
        .forEach((item) => {
            ticketsInCart[item.itemID] = item.quantity
        })

    const zonesSlider: any = getSettings('#zone-slickbox #zone-slick')
    /* event handler functions */

    const updateGroupSizeOptions = (hotelId: string) => {
        const hotel = event.hotels.find((h) => h.id === hotelId)!
        let newGroupSizeOptions = [...groupSizeOptions]
        let unlimitedInventoryRoomTiers: Record<string, boolean> = {}
        hotel.rooms.forEach((room) => {
            room.tiers.forEach((tiers) => {
                unlimitedInventoryRoomTiers[tiers.id] = tiers.prices.every(
                    (price) => price.quantityAvailable === -1
                )
            })
        })
        let excludingInventory = false
        const totalTiersPriceData = totalTiersData(excludingInventory).find(
            (hotelTotalTable) => hotelTotalTable.hotelID === hotel.id
        )
        const availableRoom = hotel.rooms.filter((room) => {
            if (!room.onSale) return false
            const availableRoomTier = room.tiers.filter((tier) =>
                getRoomTierTimeSaleAble(isCodeValid, tier)
            )
            return availableRoomTier.length > 0
        })
        newGroupSizeOptions = newGroupSizeOptions.map((groupSizeOption) => {
            const groupSize = groupSizeOption.value
            const price = getCheapestPrice(
                availableRoom,
                groupSize,
                hotel.id,
                false,
                hotel.overBaseOccupancyFee,
                totalTiersPriceData,
                unlimitedInventoryRoomTiers
            )
            if (price === 99999999 || price === 1000000000) {
                return {
                    ...groupSizeOption,
                    isDisabled: true
                }
            }
            return groupSizeOption
        })
        const oneGuestIsDisabled = newGroupSizeOptions[0].isDisabled
        const twoGuestIsDisabled = newGroupSizeOptions[1].isDisabled
        if (!oneGuestIsDisabled && twoGuestIsDisabled) {
            setSelectedGroupSize(newGroupSizeOptions[0].value)
            setSelectedGuestsOption(newGroupSizeOptions[0])
        }
        setGroupSizeOptions(newGroupSizeOptions)
    }

    const onHotelCardClick = (hotelId: string, onButton: boolean) => {
        if (isPreSale && !isCodeValid) return
        setSelectedHotelId(hotelId)
        updateGroupSizeOptions(hotelId)
        openGuestsModal()
    }

    const onHandleImageClick = (hotelImageUrl: any[]) => {
        setHotelImageUrl(hotelImageUrl)
        setAllImageCardGallery(true)
    }

    const handleSelectedGuestsOptionChange = (newValue: any) => {
        setSelectedOptionIsPlaceholder(false)
        setSelectedGroupSize(newValue.value)
        setSelectedGuestsOption(newValue)
    }

    const openGuestsModal = () => {
        setShowGuestsModal(true)
    }

    const hideGuestsModal = () => {
        setShowGuestsModal(false)
    }

    const resetLocalStorgeCheckInAndOutDate = (
        cartInfo: AddToCartInfo,
        hasAddToCart: boolean,
        selectedHotelId: string
    ) => {
        let localStorageDate = {}
        let previousHotelId: string | undefined = ''
        let previousCheckInDate
        let previousCheckOutDate
        const hasAddToCartButInOtherEvent = cart.items.length !== 0 && event.id !== cart.eventID
        switch (true) {
            case hasAddToCart:
                previousHotelId = cartInfo.previousHotelID
                previousCheckInDate = cartInfo.firstCheckInDate
                previousCheckOutDate = cartInfo.firstCheckOutDate
                break
            case hasAddToCartButInOtherEvent:
                previousCheckInDate = event.checkInDate
                previousCheckOutDate = event.checkOutDate
                if (event.id === localCartInfo.comparedEventsID) {
                    previousCheckInDate = cartInfo.firstSelectedCheckInDate[0]
                    previousCheckOutDate = cartInfo.firstSelectedCheckInDate[1]
                }
                break
            case cartInfo.firstSelectedCheckInDate !== undefined:
                previousHotelId = cartInfo.hasSelectedHotelID
                previousCheckInDate = cartInfo.firstSelectedCheckInDate[0]
                previousCheckOutDate = cartInfo.firstSelectedCheckInDate[1]
                break
            default:
                previousCheckInDate = event.checkInDate
                previousCheckOutDate = event.checkOutDate
                break
        }

        switch (true) {
            case selectedHotelId !== previousHotelId:
                localStorageDate = {
                    checkInDate: event.checkInDate,
                    checkOutDate: event.checkOutDate
                }
                break
            case cart.items.length === 0 && event.id !== localCartInfo.comparedEventsID:
                localStorageDate = {
                    checkInDate: event.checkInDate,
                    checkOutDate: event.checkOutDate,
                    firstSelectedCheckInDate: [event.checkInDate, event.checkOutDate]
                }
                break
            default:
                localStorageDate = {
                    checkInDate: previousCheckInDate,
                    checkOutDate: previousCheckOutDate
                }
                break
        }

        return localStorageDate
    }

    const handleConfirmSelectedGuestsOption = () => {
        if (guestsSubmitting) return
        setGuestsSubmitting(true)
        const numberOfGuests = selectedGuestsOption.value
        const localStorageInfo = {
            code: code,
            eventID: event.id,
            eventSlug,
            groupSize: selectedGroupSize,
            hasClickNavbar: false,
            hotelID: selectedHotelId,
            isCodeValid,
            isPreSale,
            selectedTicketNumber: ''
        }
        if (hasShoulderNights) {
            setShowGuestsModal(false)
            const hotelShoulderNights = getShoulderNightsByHotelId(
                event,
                hotel,
                inventory,
                '',
                numberOfGuests
            )

            const addComparedEventsIDData = {
                comparedEventsID: '',
                hasSelectedHotelID: ''
            }
            const checkInAndOutData = resetLocalStorgeCheckInAndOutDate(
                localCartInfo,
                hasAddToCart,
                hotel!.id
            )

            updateEventProp({
                ...localStorageInfo,
                ...checkInAndOutData,
                ...addComparedEventsIDData
            })
            if (hotelShoulderNights) {
                setMinSelectDate(hotelShoulderNights[0]!)
                setMaxSelectDate(hotelShoulderNights[1]!)
            }
            setIsDateRangePickerModalOpen(true)
            setGuestsSubmitting(false)
        } else {
            const selectedItem: GAProductType = {
                affiliation: APP_NAME,
                coupon: undefined,
                discount: undefined,
                index: 0,
                item_brand: hotel?.name ?? '',
                item_category: `${hotel?.name} item`,
                item_category2: undefined,
                item_category3: undefined,
                item_category4: undefined,
                item_category5: undefined,
                item_id: selectedHotelId,
                item_list_id: selectedHotelId,
                item_list_name: hotel?.name + ' item list',
                item_name: `${hotel?.name}`,
                item_variant: undefined,
                location_id: undefined,
                price: undefined,
                quantity: numberOfGuests
            }
            googleAnalyticsEvent({
                action: GoogleAnalyticsEventActionType.SELECT_ITEM,
                itemListId: selectedHotelId,
                itemListName: hotel?.name + ' item list',
                items: [selectedItem]
            })
            updateEventProp({
                ...localStorageInfo,
                checkInDate: event.checkInDate,
                checkOutDate: event.checkOutDate,
                hasSelectedHotelID: ''
            })
            router
                .push(`/events/${eventSlug}/${selectedHotelId}/${numberOfGuests}/${queryParams}`)
                .catch(() => {
                    setGuestsSubmitting(false)
                })
        }
    }

    const handleClickDateRangePickerModalContinue = () => {
        setSubmitting(true)
        const selectedItem: GAProductType = {
            affiliation: APP_NAME,
            coupon: undefined,
            discount: undefined,
            index: 0,
            item_brand: hotel?.name ?? '',
            item_category: `${hotel?.name} item`,
            item_category2: undefined,
            item_category3: undefined,
            item_category4: undefined,
            item_category5: undefined,
            item_id: selectedHotelId,
            item_list_id: selectedHotelId,
            item_list_name: hotel?.name + ' item list',
            item_name: `${hotel?.name}`,
            item_variant: undefined,
            location_id: undefined,
            price: undefined,
            quantity: 1
        }
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.SELECT_ITEM,
            itemListId: selectedHotelId,
            itemListName: hotel?.name + ' item list',
            items: [selectedItem]
        })
        router
            .push(`/events/${eventSlug}/${selectedHotelId}/${selectedGroupSize}/${queryParams}`)
            .catch(() => {
                setSubmitting(false)
                setIsDateRangePickerModalOpen(false)
            })
    }
    const addToLocalCartInfo = (ticketId: string) => {
        let newCheckTimeInfo = {}
        if (
            localCartInfo.selectedTicketNumber === undefined ||
            localCartInfo.selectedTicketNumber !== ticketId
        ) {
            newCheckTimeInfo = {
                checkInDate: event.checkInDate,
                checkOutDate: event.checkOutDate,
                firstCheckInDate: event.checkInDate,
                firstCheckOutDate: event.checkOutDate,
                firstSelectedCheckInDate: [event.checkInDate, event.checkOutDate]
            }
        }
        setLocalStorage('addToCartInfo', {
            ...localCartInfo,
            ...newCheckTimeInfo,
            code: code,
            eventID: event.id,
            eventSlug,
            groupSize: hasAddToCart ? localCartInfo.groupSize : null,
            hasBeenToHotelPageEventID: hasAddToCart ? localCartInfo.hasBeenToHotelPageEventID : '',
            hasClickNavbar: false,
            isCodeValid,
            isPreSale,
            selectedTicketNumber: ticketId
        })
        const selectedTicket = eventTickets.find((ticket) => ticket.id === ticketId)
        const selectedItem: GAProductType = {
            affiliation: APP_NAME,
            coupon: undefined,
            discount: undefined,
            index: 0,
            item_brand: event?.name ?? '',
            item_category: `${event?.name} item`,
            item_category2: undefined,
            item_category3: undefined,
            item_category4: undefined,
            item_category5: undefined,
            item_id: selectedTicket?.tiers?.[0]?.id ?? '',
            item_list_id: event.id,
            item_list_name: event?.name + ' item list',
            item_name: `${selectedTicket?.name}`,
            item_variant: undefined,
            location_id: undefined,
            price: selectedTicket?.tiers?.[0]?.price ?? 0,
            quantity: selectedTicket?.tiers?.[0]?.quantityAvailable ?? 0
        }
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.SELECT_ITEM,
            itemListId: event.id,
            itemListName: event?.name + ' item list',
            items: [selectedItem]
        })
        router.push(`/events/${eventSlug}/group-size${queryParams}`).catch(() => {
            setSubmitting(false)
        })
    }
    const handlePackageSelection = (ticketId: string) => {
        if (submitting) return
        setSubmitting(true)
        setSubmitTicketId(ticketId)
        if (hasAddToCart) {
            const selectedTicketNumber =
                cart.items.find((item) => item.type === 'ticket')?.itemID || ''
            if (ticketId === selectedTicketNumber) {
                addToLocalCartInfo(ticketId)
                return
            }
        } else if (localCartInfo.selectedTicketNumber === ticketId) {
            addToLocalCartInfo(ticketId)
            return
        }
        if (!eventNameOverrideTicketNumber.includes(ticketId)) {
            const eventNameOverride = getEventNameOverride(event, ticketId)
            if (eventNameOverride !== '') {
                setEventNameOverrideTicketNumber([...eventNameOverrideTicketNumber, ticketId])
                setShowEventNotIncludeTicketModal(true)
                return
            }
        }
        addToLocalCartInfo(ticketId)
    }

    const renderSelectTickets = () => {
        if (event.tickets.length <= 0) return null
        return (
            <>
                {!isLoading && (
                    <SelectTicketNotification
                        email={email}
                        event={event}
                        hiveState={hiveState}
                        hotelNumInfos={hotelNumInfos}
                        hotelOnly={isOnlySellHotel}
                        isCodeValid={isCodeValid || hasAddToCart}
                        isOnlySellTickets={isOnlySellTickets}
                        isPreSale={isPreSale}
                        onEmailChange={setEmail}
                        onNotifyClick={() => {
                            hiveSubscribe(email, event.name)
                        }}
                        ticketNumInfos={ticketNumInfos}
                    />
                )}
                <div className={classes.row}>{renderTickets()}</div>
            </>
        )
    }

    const renderTickets = () => {
        const cardHasDescription = eventTickets.some((ticket) => ticket.description)
        const cardHasPrice = ticketsStatus.some((ticket) => ticket.price && ticket.shouldShowPrice)
        return eventTickets.map((ticket, index) => {
            if (ticket.hidden) return null
            const ticketTip = ticketTips.find((tip) => tip.ticketID === ticket.id)
            const ticketStatus =
                ticketsStatus.find((state) => ticket.id === state.ticketId) || defaultTicketState
            return (
                <TicketTypeSelection
                    cardHasDescription={cardHasDescription}
                    cardHasPrice={cardHasPrice}
                    currency={currency}
                    isLoading={isLoading}
                    key={'ticket_' + ticket.id}
                    onPackageSelectionClick={handlePackageSelection}
                    onUnlockButtonClick={renderPreSalePopUp}
                    peopleNumber={peopleNumber}
                    submitting={submitting && submitTicketId === ticket.id}
                    ticket={ticket}
                    ticketIndex={index}
                    ticketNameResize={handleTicketNameResize}
                    ticketStatus={ticketStatus}
                    ticketTip={ticketTip}
                />
            )
        })
    }

    const renderHotelOnlySoldOutNotification = () => {
        if (event.tickets.length <= 0 && hotelNumInfos[ON_SALE] <= 0) {
            return (
                <SelectTicketNotification
                    email={email}
                    event={event}
                    hiveState={hiveState}
                    hotelNumInfos={hotelNumInfos}
                    hotelOnly={isOnlySellHotel}
                    isCodeValid={isCodeValid || hasAddToCart}
                    isOnlySellTickets={isOnlySellTickets}
                    isPreSale={isPreSale}
                    onEmailChange={setEmail}
                    onNotifyClick={() => {
                        hiveSubscribe(email, event.name)
                    }}
                    ticketNumInfos={ticketNumInfos}
                />
            )
        }
    }

    const renderHotelCards = () => {
        if (!isOnlySellHotel) return null

        return (
            <div className={classes.row}>
                {isLoading ? (
                    <Skeleton
                        animation="wave"
                        sx={{
                            height: SIZE_30,
                            marginBottom: SIZE_22,
                            width: FULL_WIDTH
                        }}
                    />
                ) : (
                    <div
                        className="col-sm-12 gx-md-0 gx-md-3"
                        id="selectHotel"
                    >
                        <h2
                            className={`headline-2 mobile-padding-left ${isF1 ? 'f1-font' : ''}`}
                            style={{ marginBottom: '22px' }}
                        >
                            Choose Your Accommodation
                        </h2>
                    </div>
                )}
                {event.hotels.map((hotel: HotelJSON, index) => {
                    const hotelStatus =
                        hotelsStatus.find((state) => hotel.id === state.hotelId) ||
                        defaultHotelState
                    const hotelShoulderNights = getShoulderNightsByHotelId(
                        event,
                        hotel,
                        inventory,
                        '',
                        selectedGroupSize
                    )
                    return (
                        <HotelCard
                            currency={event.currency}
                            dateDisplay={dateRange(event.checkInDate, event.checkOutDate, timezone)}
                            hotel={hotel}
                            hotelIndex={index}
                            hasShoulderNights={hotelShoulderNights !== undefined}
                            hotelStatus={hotelStatus}
                            isCodeValid={isCodeValid}
                            isLoading={isLoading}
                            isOnlySellHotel={isOnlySellHotel}
                            isMobileWidth={isMobileWidth}
                            isPreSale={isPreSale}
                            key={hotel.id}
                            onHandleImageClick={onHandleImageClick}
                            onHotelCardClick={onHotelCardClick}
                            onUnlockButtonClick={renderPreSalePopUp}
                            submitting={submitting && selectedHotelId === hotel.id}
                            hotelShoulderNights={undefined}
                        />
                    )
                })}
            </div>
        )
    }

    const renderEventMap = () => {
        if (!isOnlySellTickets) {
            return (
                <EventMap
                    event={event}
                    isF1={isF1}
                />
            )
        }
    }

    const renderEventTimeLine = () => {
        if (itinerary.length <= 0 || isOnlySellTickets) return null
        return (
            <EventTimeLine
                itinerary={itinerary}
                timezone={timezone}
            />
        )
    }

    const renderPreSalePopUp = () => {
        setShowPreSalePopUp(true)
    }

    const hidePreSalePopUp = () => {
        setShowPreSalePopUp(false)
        setCode('')
        setCodeError('')
    }

    const handleValidCode = async () => {
        setCodeError('')
        setSubmitting(true)
        if (code != '') {
            checkCode(
                publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                event.id,
                code.toLowerCase()
            ).then((data) => {
                if (data == undefined) {
                    setIsCodeValid(true)
                    setCodeError('')
                    setSubmitting(false)
                    setShowPreSalePopUp(false)
                    setIsPreSale(false)
                } else {
                    switch (data.message) {
                        case 'Not Found':
                            setCodeError('Please enter a correct code')
                            setSubmitting(false)
                            break
                        case 'invalid code':
                            setCodeError('Incorrect presale code. Please try again.')
                            setSubmitting(false)
                            break
                    }
                }
            })
        } else {
            setCodeError('Please enter a correct code')
            setSubmitting(false)
        }
    }

    const footerAddPBCSS = isMobile ? (isTablet ? 'pc-mb2' : 'mobile-mb2') : 'pc-mb2'

    useEffect(() => {
        const event_tickets_available = event.tickets?.filter(
            (t) =>
                t.quantityAvailable > 0 && t.tiers.some((t) => t.quantityAvailable > 0 && t.onSale)
        )
        const event_accommodations_available = event.hotels?.filter((h) =>
            h.rooms.some((r) => r.quantityAvailable > 0 && r.tiers.some((t) => t.onSale))
        )
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.PAGE_VIEW,
            category: event.name,
            label: window.location.toString(),
            pageTitle: event.name + ' - Event Page Detail'
        })
        const gaItems = isOnlySellHotel ? event_accommodations_available : event_tickets_available
        const eventItem = {
            affiliation: APP_NAME,
            coupon: undefined,
            discount: undefined,
            index: 0,
            item_brand: event.name,
            item_category: `${event.name} item`,
            item_category2: undefined,
            item_category3: undefined,
            item_category4: undefined,
            item_category5: undefined,
            item_id: event.id,
            item_list_id: event.id,
            item_list_name: event.name + ' item list',
            item_name: `${event.name}`,
            item_variant: undefined,
            location_id: undefined,
            price: undefined,
            quantity: 1
        }
        const items: GAProductType[] = gaItems.map(
            (item: TicketJSON | HotelJSON, index: number) => {
                return {
                    affiliation: APP_NAME,
                    coupon: undefined,
                    discount: undefined,
                    index: index,
                    item_brand: item.name,
                    item_category: `${event.name} item`,
                    item_category2: undefined,
                    item_category3: undefined,
                    item_category4: undefined,
                    item_category5: undefined,
                    item_id: item.id,
                    item_list_id: event.id,
                    item_list_name: event.name + ' item list',
                    item_name: `${event.name}`,
                    item_variant: undefined,
                    location_id: undefined,
                    price: isOnlySellHotel
                        ? undefined
                        : +((item as TicketJSON).tiers?.[0]?.price / 100 + 100.0).toFixed(2),
                    quantity: isOnlySellHotel
                        ? (item as HotelJSON).rooms.length
                        : (item as TicketJSON).tiers?.[0]?.quantityAvailable
                }
            }
        )
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.VIEW_ITEM,
            currency: 'USD',
            items: [eventItem],
            value: undefined
        })
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.VIEW_ITEM_LIST,
            itemListId: event.id,
            itemListName: event.name + ' item list',
            items: items
        })
    }, [])

    const handleNavBarClick = () => {
        if (!hasAddToCart) return
        setLocalStorage('addToCartInfo', {
            ...localCartInfo,
            checkInDate: localCartInfo.firstCheckInDate,
            checkOutDate: localCartInfo.firstCheckOutDate,
            code: localCartInfo.presaleCode !== undefined ? localCartInfo.presaleCode : '',
            comparedEventsID: cart.eventID,
            eventSlug: localCartInfo.previewEventSlug,
            eventID: cart.eventID,
            groupSize: cart.groupSize,
            hasBeenToHotelPageEventID: event.id,
            hasClickNavbar: true,
            isCodeValid,
            selectedTicketNumber: localCartInfo.previousSelectedTicketNumber,
            ticketItems: localCartInfo.previousTicketItems,
            ticketExperienceItems: localCartInfo.ticketExperienceItems
        })
    }

    return (
        <div
            itemScope
            itemType="https://schema.org/Event"
        >
            <div style={{ display: 'none' }}>
                <div itemProp="url">{`http://vibee.com/events/${eventSlug}/${queryParams}`}</div>
                <div itemProp="name">{event.meta.title || event.name}</div>
                <div
                    itemProp="description"
                    dangerouslySetInnerHTML={{ __html: description }}
                ></div>
                <div
                    className="paragraph-multi fw-500"
                    itemProp="location"
                    itemScope
                    itemType="https://schema.org/Place"
                >
                    <p itemProp="name">
                        {event?.locationDisplay !== '' ? locationDisplay : location}
                    </p>
                </div>
                <Image
                    src={getImage(headerImage, 'desktop').url}
                    alt=""
                    itemProp="image"
                    layout="fill"
                />
                <Image
                    src={getImage(headerImage, 'square').url}
                    alt=""
                    itemProp="image"
                    layout="fill"
                />
                <time
                    itemProp="startDate"
                    dateTime={`${getTimeZone(startDate, timezone).format('YYYY-MM-DD')}`}
                />
                <time
                    itemProp="EndDate"
                    dateTime={`${getTimeZone(endDate, timezone).format('YYYY-MM-DD')}`}
                />
                {event.tickets.map((ticket) => {
                    if (!ticket.tiers?.[0]?.price) return null

                    let availabilityTest = ''
                    let timeOfValidFrom = ' " " '

                    switch (true) {
                        case !ticket.tiers.some((tier) => tier.onSale):
                            availabilityTest = 'SoldOut'
                            break
                        case ticket.tiers[0].onSaleDate !== null:
                            availabilityTest = 'PreOrder'
                            break
                        default:
                            availabilityTest = 'InStock'
                    }

                    if (ticket.tiers[0].onSaleDate) {
                        timeOfValidFrom = getTimeZone(ticket.tiers[0].onSaleDate, timezone).format(
                            'YYYY-MM-DDTHH:mm'
                        )
                    }

                    return (
                        <div
                            itemProp="offers"
                            itemScope
                            itemType="https://schema.org/Offer"
                            key={ticket.id}
                        >
                            <p itemProp="name">{ticket.name}</p>
                            <div itemProp="description">{ticket.description}</div>
                            <div
                                itemProp="priceSpecification"
                                itemScope
                                itemType="https://schema.org/PriceSpecification"
                            >
                                <span itemProp="minPrice">{ticket.tiers[0].price / 100}</span>
                                <span itemProp="priceCurrency">{currency}</span>
                            </div>
                            <div itemProp="availability">{availabilityTest}</div>
                            <div itemProp="validFrom">{timeOfValidFrom}</div>
                        </div>
                    )
                })}
            </div>
            <Head>
                <title>{event.meta.title || event.name}</title>
            </Head>
            {isTheWeekndRoute && isLoading ? (
                <WaitingRoom
                    pageNumber={1}
                    preEntryText={`The night’s just getting started—your world’s about to change, one choice at a time.`}
                    queueText="Get ready to build your Experience!"
                />
            ) : (
                <>
                    <BodyClass
                        bodyClass={`event-detail-wrapper ${footerAddPBCSS} ${
                            isF1 ? 'f1-event-page' : ''
                        }`}
                    />
                    {showEventNotIncludeTicketBanner && <EventNotIncludeTicketBanner />}
                    <BreadCrumbsNavbar
                        accommodationUrl={navbarUrl.accommodation}
                        addOnsUrl={navbarUrl.addOn}
                        groupSizeUrl={navbarUrl.groupSize}
                        hasAddonsPage={hasAddonsPage}
                        isOnlySellHotel={isOnlySellHotel}
                        isOnlySellTickets={isOnlySellTickets}
                        packagesUrl={''}
                        previewUrl={navbarUrl.preview}
                        roomUrl={navbarUrl.room}
                        routerPathName={router.pathname}
                        showNavbar={hasAddToCart}
                        onClickBreadCrumbsNavbar={handleNavBarClick}
                    />
                    <EventTop
                        hasAddToCart={hasAddToCart}
                        headerImage={headerImage}
                        headerVideo={headerVideo}
                        isLoading={isLoading}
                        isMobileWidth={isMobileWidth}
                    />
                    <EventIntroduction
                        ageRequirement={ageRequirement}
                        dateDisplay={dateDisplay}
                        description={description}
                        endDate={endDate}
                        isLoading={isLoading}
                        isOnlySellHotel={isOnlySellHotel}
                        location={location}
                        locationDisplay={locationDisplay}
                        name={event.name}
                        startDate={startDate}
                        timezone={timezone}
                    />
                    <div>
                        <div className="container hotel-list-container package-list-container gx-3">
                            <div
                                style={{
                                    width: FULL_WIDTH
                                }}
                            >
                                {renderSelectTickets()}
                            </div>
                            {renderHotelOnlySoldOutNotification()}
                            {renderHotelCards()}
                            <div
                                data-testid="media-viewer-popup"
                                style={{ height: '100%' }}
                            >
                                {allImageCardGallery && (
                                    <HotelCardImages
                                        headerImage={hotelImageUrl}
                                        showSingleImage={showSingleImage}
                                        handleShowSingleImage={() => setShowSingleImage(true)}
                                        hideSingleImage={() => setShowSingleImage(false)}
                                        hideAllImageCardGallery={() =>
                                            setAllImageCardGallery(false)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        {renderEventTimeLine()}
                        {event.featuresAndBenefits && (
                            <div className="container included-container">
                                <div className="row">
                                    <div className="col-md-12 gx-4-2">
                                        <h2 className="text-md-center text-start event-section-headline">
                                            What&apos;s Included?
                                        </h2>
                                        <div className="mx-auto included-content">
                                            <EventFeaturesAndBenefitsSection
                                                featuresAndBenefits={event.featuresAndBenefits}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {event.bottomImage.length !== 0 && <EventBottomHero event={event} />}
                        <EventFAQ
                            faqs={faqs}
                            faqsCategories={faqsCategories}
                            hasFaqsCategories={faqsCategories.length > 0}
                        />
                    </div>

                    <PreSaleModal
                        code={code}
                        codeError={codeError}
                        handleValidCode={handleValidCode}
                        hidePreSalePopUp={hidePreSalePopUp}
                        onChangeCode={(e: any) => setCode(e.currentTarget.value)}
                        showPreSalePopUp={showPreSalePopUp}
                        submitting={submitting}
                    />
                    {showGuestsModal && (
                        <GuestsModal
                            isF1={isF1}
                            onCancelClick={hideGuestsModal}
                            onContinueClick={handleConfirmSelectedGuestsOption}
                            onSelectedOptionChange={handleSelectedGuestsOptionChange}
                            options={groupSizeOptions}
                            selectedOptionIsPlaceholder={selectedOptionIsPlaceholder}
                            selectedValue={selectedGuestsOption}
                            submitting={guestsSubmitting}
                            visible={showGuestsModal}
                        />
                    )}

                    <EventNotIncludeTicketModal
                        addToLocalCartInfo={addToLocalCartInfo}
                        isReserving={isReserving}
                        setIsReserving={setIsReserving}
                        setSubmitting={setSubmitting}
                        setShowEventNotIncludeTicketModal={setShowEventNotIncludeTicketModal}
                        show={showEventNotIncludeTicketModal}
                        submitTicketId={submitTicketId}
                    />

                    {isDateRangePickerModalOpen && (
                        <DateRangePickerModalComponent
                            addToCartDefaultValueRange={addToCartDefaultValueRange}
                            addToCartValue={addToCartValue}
                            cancelButtonTitle="Reset"
                            cartEventId={cart.items.length === 0 ? undefined : cart.eventID}
                            cartFirstCheckDateOfLocationTimeRange={
                                cartFirstCheckDateOfLocationTimeRange
                            }
                            cartFirstCheckDateRange={getCartFirstCheckDateRange()}
                            cartInfoHasSelectedHotelID={
                                localCartInfo.hasSelectedHotelID !== undefined
                                    ? localCartInfo.hasSelectedHotelID
                                    : ''
                            }
                            cartInfoPreviousHotelID={
                                localCartInfo.previousHotelID !== undefined
                                    ? localCartInfo.previousHotelID
                                    : ''
                            }
                            checkInDate={event.checkInDate}
                            checkOutDate={event.checkOutDate}
                            confirmButtonTitle="Apply"
                            defaultValueRange={defaultValueRange}
                            eventHotels={event.hotels}
                            eventId={event.id}
                            eventTimezone={timezone}
                            groupSize={selectedGroupSize}
                            hasAddToCart={hasAddToCart}
                            hasSelectedHotelID={selectedHotelId ?? ''}
                            inventory={inventory}
                            isDisabled={submitting}
                            isLoading={submitting}
                            isOnlySellHotel={isOnlySellHotel}
                            maxDate={maxSelectDate ?? undefined}
                            minDate={minSelectDate ?? undefined}
                            onClose={() => setIsDateRangePickerModalOpen(false)}
                            onContinue={handleClickDateRangePickerModalContinue}
                            onDatesChange={(value) => updateDates(value)}
                            onResetDates={() => resetDatesToInitialEventDates()}
                            openModal={isDateRangePickerModalOpen}
                            textContent={textContent}
                            ticketId={''}
                            title="Select the length of your stay"
                            updateEventProp={updateEventProp}
                            value={selectedDates}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default Event
