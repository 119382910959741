import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles<{ pageNumber: number }>()((theme, { pageNumber }) => ({
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        margin: '0 auto',
        padding: 20,
        textAlign: 'center',
        width: '100vw',
        '@media (max-width: 960px)': {
            justifyContent: 'space-between',
            marginTop: 100
        }
    },
    contentContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: pageNumber === 2 ? 'row-reverse' : 'row',
        height: '100%',
        justifyContent: 'space-evenly',
        margin: '0 auto',
        maxWidth: '1400px',
        textAlign: 'left',
        width: '100%',
        '@media (max-width: 960px)': {
            flexDirection: 'column-reverse',
            height: 'unset',
            textAlign: 'center'
        }
    },
    copyWrapper: {
        maxWidth: '464px',
        padding: 20,
        '@media (max-width: 960px)': {
            maxWidth: '90vw',
            padding: 0,
            paddingBottom: 16
        }
    },
    logoWrapper: {
        marginBottom: 20,
        marginTop: 16,
        '@media (max-width: 960px)': {
            marginTop: 0,
            marginBottom: 12
        }
    },
    boldHeader: {
        color: '#333',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '24px',
        marginBottom: 12
    },
    artistName: {
        color: '#333',
        fontSize: 30,
        fontWeight: 700,
        marginBottom: 8,
        '@media (max-width: 960px)': {
            fontSize: 24,
            lineHeight: '32px',
            marginBottom: 4
        }
    },
    preEntryText: {
        color: 'rgba(102, 112, 133, 1)',
        fontSize: 16,
        fontStyle: 'italic',
        fontWeight: 400,
        lineHeight: '24px',
        marginBottom: 12
    },
    queueText: {
        color: 'rgba(102, 112, 133, 1)',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        marginBottom: 12
    },
    imageWrapper: {
        padding: 20,
        '@media (max-width: 960px)': {
            padding: 0,
            paddingBottom: 16
        }
    },
    eventImage: {
        borderRadius: 24,
        maxHeight: 531,
        maxWidth: 426,
        '@media (max-width: 960px)': {
            maxWidth: '85vw'
        }
    },
    waitingRoomfooter: {
        alignItems: 'center',
        color: 'rgba(102, 112, 133, 1)',
        display: 'flex',
        fontSize: 14,
        justifyContent: 'center',
        textAlign: 'center'
    }
}))
